<template>
<v-container fluid fill-height v-if="show">
    <v-card :class="`align-self-stretch ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0">
            <h1>Точки доставки</h1>
        </v-card-title>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contactInfo.userId ? (contactInfo.isConfirmed ? false : true) : false">
            Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
        </v-alert>
        <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="!contractors.length && loaded ">
            Немає жодного доступного контрагента для перегляду данних.
        </v-alert>
        <v-data-table class="my-4" hide-default-footer multi-sort :headers="filteredHeaders" item-key="id" :item-class="rowClasses" disable-pagination :items="consigneesData">
            <template v-slot:item.actions="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.smAndDown" :disabled="item.status == 'closed' || contactInfo.realUserId ? true : false ">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon title="Закрити" v-if="item.status == 'active'" :disabled="contactInfo.realUserId ? true : false " @click="closePointD(item)">
                    <v-icon class="link--text">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn icon title="Відкрити" v-if="item.status == 'closed'" :disabled="contactInfo.realUserId ? true : false " @click="openPointD(item)">
                    <v-icon>mdi-eye-off-outline</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.edit="{ item }">
                <v-btn icon title="Редагувати" @click="editCard(item)" v-if="$vuetify.breakpoint.mdAndUp" :disabled="item.status == 'closed' || contactInfo.realUserId ? true : false ">
                    <v-icon class="green--text">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.address="{ item }">
               {{item.pindex ? `${item.pindex}, `:``  }} {{item.city_name}}, {{item.street_name}}, {{item.building}}
                <div v-if="item.address">(Дод. до адреси: {{item.address}})</div>
            </template>
            <template v-slot:item.phone="{ item }">
               <div class="text-truncate">{{item.phone}}</div>
            </template>
            <template v-slot:item.type="{ item }">
                <v-chip :color="item.type == 'company' ? 'link' : ''" small outlined>{{item.type == 'company' ? 'Drop-shipping' : 'Клієнт'}}</v-chip>
            </template> 
        </v-data-table>
        <v-card-actions>
            <v-btn :disabled="contactInfo.realUserId ? true : false " @click="editCard()">Додати точку</v-btn>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" persistent width="700" :fullscreen="$vuetify.breakpoint.mdAndUp ? false : true">
        <v-system-bar dark>
            <v-spacer></v-spacer>
            <v-icon @click="dialog=false">mdi-close</v-icon>
        </v-system-bar>
        <v-card>
            <v-card-title class="headline">
                Картка Точки доставки
            </v-card-title>
            <v-card-text v-if="f.typhoon_id" class="text-right font-weight-bold">
                Номер: {{f.id}}
            </v-card-text>
            <v-card-text v-if="!f.id">
                Перед додаванням нової точки доставки впевніться, що її ще немає у списку. Якщо ви її знайшли у закритих точках, то відкрийте її наново.
            </v-card-text>
            <!-- {{f}}  -->
            <v-alert type="info" text outlined dense v-if="f.typhoon_id" class="mx-4">
                Увага! Точка доставки внесена у облікову систему.<br>
                Доступні для редагування лише контактні реквізити.<br>
                Якщо точка неактуальна, то Ви можете її закрити.<br>

            </v-alert>
            <div class="mx-4">
                <v-autocomplete v-model="f.type" outlined :items="typeItems" :disabled="disabledItem()" label="Тип *"></v-autocomplete>
                <v-text-field outlined label="Назва *:" :disabled="disabledItem()" v-model="f.consignee_name"></v-text-field>
                <v-autocomplete v-model="f.contractor_id" item-text="name" :disabled="disabledItem()" item-value="id" outlined :items="contractors" label="Контрагент"></v-autocomplete>

                <v-subheader class="text-subtitle-2">Адреса</v-subheader>
                <v-autocomplete v-model="f.region" outlined :items="regions" :disabled="disabledItem()" @change="getCities()" label="Область *"></v-autocomplete>
                <v-text-field outlined label="Індекс:" placeholder="00000" v-mask="indexMask" v-model="f.pindex"></v-text-field>
                <v-autocomplete v-model="f.city" outlined auto-select-first :disabled="disabledItem()" :filter="customFilter" :items="cities" label="Місто *"></v-autocomplete>
                <v-autocomplete v-model="f.street" auto-select-first outlined :disabled="disabledItem()" :search-input.sync="search" :items="streets" label="Вулиця *" no-data-text="Введіть назву вулиці"></v-autocomplete>
                <v-text-field outlined label="Будинок *:" :disabled="disabledItem()" v-model="f.building"></v-text-field>
                <v-text-field outlined label="Дод. до адреси:"  v-model="f.address"></v-text-field>

                <v-subheader class="text-subtitle-2">Контактна особа</v-subheader>
                <v-text-field outlined label="Прізвище *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="f.surname"></v-text-field>
                <v-text-field outlined label="Ім'я  *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="f.first_name"></v-text-field>
                <v-text-field outlined label="По-батькові *:" :rules="[rules.noSpaces, rules.noDigits]" v-model="f.second_name"></v-text-field>
                <v-text-field outlined label="Тел. *:" v-model="f.phone" placeholder="+38(000) 000 - 0000" v-mask="maskTel"></v-text-field>

            </div>

            <v-alert type="warning" text outlined dense class="mx-4">
                Увага! Усі поля позначені зірочкою, обов'язкові до заповнення!
            </v-alert>
            <v-card-actions>
                <v-btn color="primary" text :loading="loading" :disabled="!checkForm" @click="submitForm()">
                    Зберегти
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog=false">Закрити</v-btn>
            </v-card-actions>
            <v-fade-transition>
                <v-overlay :value="overlay" absolute opacity='0.25'>
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-fade-transition>

        </v-card>
    </v-dialog>
    <v-dialog v-model="closePointDialod" width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="warning white--text">Увага!</v-card-title>
            <v-card-text class="mt-6">
                <p>Ви впевнені, що точка доставки вже втратила свою актуальність і її потрібно закрити?</p>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" :loading="deliveryLoading" @click="closePoint()">
                    підтвердити
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="closePointDialod=false">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="openPointDialod" width="600" max-width="100%">
        <v-card width="100%">
            <v-card-title class="warning white--text">Увага!</v-card-title>
            <v-card-text class="mt-6">
                <p>Повернути точку доставки в роботу?</p>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" :loading="deliveryLoading" @click="openPoint()">
                    підтвердити
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="openPointDialod=false">
                    Скасувати
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import {
    mapActions
} from 'vuex'

import {
    mask
} from 'vue-the-mask'

export default {
    components: {},
    data: () => ({
        show: false,
        loading: false,
        loaded: false,
        dialog: false,
        overlay: false,
        idForClose: null,
        idForOpen: null,
        openPointDialod: false,
        closePointDialod: false,
        deliveryLoading: false,
        typeItems: [
            {value: 'company', text: 'Drop-shipping'},
            {value: 'person', text: 'Клієнт'} 
        ],
        f: {},
        search: '',
        maskTel: '+38# ## ### ####',
        indexMask: '#####',
        headers: [{
                text: '',
                align: 'left',
                sortable: false,
                value: 'actions',
                width: '2%',
                selected: true,
            },
            {
                text: 'Номер',
                align: 'left',
                sortable: true,
                value: 'id',
                width: '6%',
                selected: true,
            },
            {
                text: 'Тип',
                align: 'center',
                value: 'type',
                selected: true,
                width: '10%',
            },
            {
                text: 'Назва',
                align: 'center',
                value: 'name',
                selected: true,
                width: '20%',
            },
            {
                text: 'Область',
                align: 'center',
                value: 'region',
                selected: true,
                width: '15%',
            },
            {
                text: 'Адреса',
                align: 'center',
                value: 'address',
                selected: true,
                width: '25%',
            },
            {
                text: 'ПІБ',
                align: 'center',
                value: 'fio',
                selected: true,
                width: '15%',
            },
            {
                text: 'Телефон',
                align: 'right',
                value: 'phone',
                selected: true,
                width: '10%',
            },
            {
                text: '',
                align: 'right',
                sortable: false,
                value: 'edit',
                width: '2%',
                selected: true,
            },
        ],
        rules: {
            noSpaces: value => {
                const pattern = /\s+/
                return pattern.test(value) ? 'У тексті не повинно бути пробілів ' : true
            },
            noDigits: value => {
                const pattern = /\d+/
                return pattern.test(value) ? 'У тексті не повинно бути цифр ' : true
            },

        }
    }),
    directives: {
        mask
    },
    watch: {
        search(val) {
            val && val !== this.select && this.querySelections(val)
        },
    },

    methods: {
        ...mapActions(['touch', 'getContactInfo', 'getCitiesAutocomplete', 'getRegionsAutocomplete', 'setSnackbar',
            'getStreetsAutocomplete', 'getOrderContractors', 'submitConsigneeData', 'getConsigneesData', 'getConsigneesCardData', 'closeDeliveryPoint', 'openDeliveryPoint'
        ]),
        getCities() {
            this.overlay = true
            this.getCitiesAutocomplete(this.f.region)
                .then(() => {
                    this.overlay = false
                })
        },
        querySelections(v) {
            this.overlay = true
            this.getStreetsAutocomplete({
                    cityId: this.f.city,
                    keyword: v
                })
                .then(() => {
                    this.overlay = false
                })
        },
        submitForm() {
            this.loading = true
            this.$set(this.f, 'surname', this.f.surname.trim())
            this.$set(this.f, 'first_name', this.f.first_name.trim())
            this.$set(this.f, 'second_name', this.f.second_name.trim())
            this.submitConsigneeData(this.f)
                .then(() => {
                    this.setSnackbar(['success', 'Данні прийняті. Зміни будуть відображені в обліковій системі протягом 30 хвилин.'])
                    this.getConsigneesData()
                    this.loading = false
                    this.dialog = false
                })
                .catch(error => {
                    console.error(error)
                    this.loading = false
                    if (error.response && error.response.status == 403) {
                        this.setSnackbar(['error', 'Точка доставки с такими реквізитами вже існує.'])
                    } else {
                        this.setSnackbar(['error', ''])
                    }
                })
        },
        editCard(item) {
            this.dialog = true
            this.overlay = true
            this.f = {}
            this.$store.commit('setStreets', [])

            if (item && item.id) {
                this.getConsigneesCardData(item.id)
                    .then(data => {
                        this.f = data
                        this.getCitiesAutocomplete(data.region)
                            .then(() => {
                                this.$set(this.f, 'city', data.city_id)
                                this.$set(this.f, 'street', String(data.street))
                                this.getStreetsAutocomplete({
                                        cityId: this.f.city,
                                        streetId: this.f.street,
                                    })
                                    .then(() => {
                                        // this.search = this.streets[0].text
                                        this.overlay = false
                                    })
                            })
                    })
            } else {
                this.overlay = false
            }
        },
        disabledItem() {
            return this.f.typhoon_id ? true : false
        },
        openPoint() {
            this.deliveryLoading = true
            this.openDeliveryPoint(this.idForOpen)
                .then(() => {
                    this.openPointDialod = false
                    this.deliveryLoading = false
                    this.setSnackbar(['success', 'Данні прийняті. Зміни будуть відображені в обліковій системі протягом 30 хвилин.'])
                    this.getConsigneesData()
                })
                .catch(error => console.log(error))
        },
        openPointD(item) {
            this.idForOpen = item.id
            this.openPointDialod = true
        },
        closePoint() {
            this.deliveryLoading = true
            this.closeDeliveryPoint(this.idForClose)
                .then(() => {
                    this.closePointDialod = false
                    this.deliveryLoading = false
                    this.setSnackbar(['success', 'Данні прийняті. Зміни будуть відображені в обліковій системі протягом 30 хвилин.'])
                    this.getConsigneesData()
                })
                .catch(error => console.log(error))
        },
        closePointD(item) {
            this.idForClose = item.id
            this.closePointDialod = true
        },
        rowClasses(item) {
            if (item.status == 'closed') {
                return "closedRow"
            }
        },
        customFilter(item, queryText, itemText) {
            const textOne = item.text.toLowerCase()
            const textTwo = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
                textTwo.indexOf(searchText) > -1
        },

    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        regions() {
            return this.$store.state.order.regions
        },
        cities() {
            return this.$store.state.order.cities
        },
        streets() {
            return this.$store.state.order.streets
        },
        contractors() {
            return this.$store.state.order.contractors
        },
        consigneesData() {
            return this.$store.state.order.consigneesData
        },
        checkForm() {
            return this.f.type &&
                this.f.consignee_name &&
                this.f.region &&
                this.f.city &&
                this.f.street &&
                this.f.building &&
                this.f.surname && !/\s+/.test(this.f.surname) &&
                this.f.first_name && !/\s+/.test(this.f.first_name) &&
                this.f.second_name && !/\s+/.test(this.f.second_name) &&
                (this.f.phone && this.f.phone.length == 16 ? true : false) &&
                (this.f.pindex && this.f.pindex.length !== 5 ? false : true )
        },
        filteredHeaders() {
            return this.headers.filter(h => {
                return h.selected && !h.hidden
            })
        },

    },
    created() {
        this.touch()
            .then(() => {
                this.getOrderContractors()
                    .then(() => this.loaded = true)
                this.getContactInfo()
                    .then(d => {
                        if (!d.isSellToEmployee && d.groups && d.groups.cladmin_delivRecipient) {
                            this.show = true
                            this.getRegionsAutocomplete()
                            this.getConsigneesData()
                        } else {
                            this.$router.push('/403')
                        }
                    })
            })
            .catch(error => console.log(error))

    }
}
</script>

<style>
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
    padding-left: 0;
}

.closedRow {
    background-color: rgb(196, 196, 196);
    color: grey;
}
</style>
