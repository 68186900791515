<template>
    <DeliveryPointsPage />
</template>

<script>
import DeliveryPointsPage from '../components/DeliveryPointsPage.vue'

export default {
    components: {
        DeliveryPointsPage
    },
    data: () => ({
    }),
    methods: {
    },
    mounted() {
    },
}
</script>

